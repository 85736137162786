<template>

    <div class="h-full w-full rounded-lg flex flex-col relative bg-filters" @click="goto('SwipSLSMU')">

        <loader :loading="loading" />

        <div class="h-8 p-3 flex flex-row justify-between items-center">
            <span class="font-semibold text-xs" style="color: #13F2E3">
                Interoperabilidad
            </span>
        </div>

        <div class="flex-1 min-h-0 flex flex-col justify-center items-center">
            <div class="h-auto flex flex-col justify-center items-center -mt-2">
                <span class="text-white font-semibold text-sm">
                    {{ interIntra.purchase_hours_sl | numberFormat(0) }}
                </span>
                <span class="text-font-gray mt-1" style="font-size: x-small">Purchased hours</span>
                <span class="text-white font-semibold text-sm mt-2">
                    {{ interIntra.sold_hours_sl | numberFormat(0) }}
                </span>
                <span class="text-font-gray mt-1" style="font-size: x-small">Sold hours</span>
            </div>
        </div>

    </div>

</template>

<script>

    const ChartDoubleGauge = () => import('@/charts/ChartDoubleGauge.vue');
    import { state } from '@/store';

    export default {
        name: "InterIntraHomeMobile",
        components: {
            ChartDoubleGauge
        },
        data() {
            return {
                loading: false,
                interIntra: false,
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
        },
        watch: {
            params() { this.load() }
        },
        methods: {
            load() {
                this.loading = true;
                if(this.params) {
                    this.axios.get('inter_intra/hours_purchased_sold', {params: this.params}).then(response => {
                        this.interIntra = response.data.data
                        this.loading = false
                    });
                }
            },
            goto(route) { this.$router.push( { name: route } )}
        },
        mounted(){
            this.load();
        }
    }
</script>